import React from "react"

import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="著作権について"
      pagedesc="著作権について"
      pagepath={location.pathname}
      // pageimg={data.about.childImageSharp.original.src}
      // pageimgw={data.about.childImageSharp.original.width}
      // pageimgh={data.about.childImageSharp.original.height}
    />
    <article className="content flex-grow">
      <div className="container">
        <h1 className="bar">著作権について</h1>
        <div className="postbody">
          <p>本ホームページに掲載の文章・画像写真などを無断で複製することは法律上禁じられています。<br />本ホームページの画像および文章の著作権は、下記の個人および企業に 帰属します。</p>
          <ul>
            <li><FontAwesomeIcon icon={faCheckSquare} /> 英文エッセイ: Walter J. Humphery</li>
            <li><FontAwesomeIcon icon={faCheckSquare} /> 英文エッセイ和訳文：株式会社ユニテック</li>
            <li><FontAwesomeIcon icon={faCheckSquare} /> 日本文化キーワード集：株式会社ユニテック</li>
            <li><FontAwesomeIcon icon={faCheckSquare} /> イラスト・写真：株式会社ユニテック（フリー素材集提供のイラスト・写真を除く）</li>
          </ul>
        </div>
      </div>
    </article>
  </Layout>
)
